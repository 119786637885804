import React from 'react';
// import PropTypes from 'prop-types';

import { FormattedMessage } from 'gatsby-plugin-intl';

import { animateScroll, Link as ScrollLink } from 'react-scroll';

import { makeStyles } from '@material-ui/core/styles';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Fade from '@material-ui/core/Fade';

import UpNorthLogo from './UpNorthLogo';

import messages from '../messages';

import upnorthLogo from '../images/logos/upnorth.svg';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backdropFilter: 'blur(10px)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    // borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  appBarScrolled: {
    borderBottom: '1px solid rgba(0,0,0,0)',
  },
  toolBar: {
    transition: ['min-height'],
    transitionDuration: 300,
  },
  logo: {
    height: '30px',
    width: 'auto',
    cursor: 'pointer',
  },
  navLink: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
  },
  navLinkActive: {},
}));

function NavBar() {
  const classes = useStyles();

  const navbarShadowScrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  const logoInNavbarScrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
  });

  return (
    <AppBar
      elevation={navbarShadowScrollTrigger ? 4 : 0}
      position="fixed"
      className={`${classes.appBar} ${
        navbarShadowScrollTrigger && classes.appBarScrolled
      }`}
      color="transparent"
    >
      <Container maxWidth="lg">
        <Toolbar
          className={classes.toolBar}
          variant={navbarShadowScrollTrigger ? 'dense' : 'regular'}
        >
          <Fade
            in={logoInNavbarScrollTrigger}
            onClick={() =>
              animateScroll.scrollToTop({
                to: 0,
                duration: 300,
                smooth: true,
              })
            }
          >
            <img src={upnorthLogo} alt="navbar-logo" className={classes.logo} />
          </Fade>
          <div style={{ flexGrow: 1 }} />
          <ScrollLink
            activeClass={classes.navLinkActive}
            className={classes.navLink}
            to="portfolio"
            duration={300}
            offset={-80}
            smooth
            spy
          >
            <Typography variant="h6">
              <FormattedMessage {...messages.portfolio} />
            </Typography>
          </ScrollLink>

          <ScrollLink
            activeClass={classes.navLinkActive}
            className={classes.navLink}
            to="team"
            duration={300}
            offset={-80}
            smooth
            spy
          >
            <Typography variant="h6">
              <FormattedMessage {...messages.team} />
            </Typography>
          </ScrollLink>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

NavBar.propTypes = {};

export default NavBar;
