import React, { useState, useEffect } from 'react';

import { FormattedMessage } from 'gatsby-plugin-intl';

import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';

import { Element as ScrollElement, Link as ScrollLink } from 'react-scroll';

import { Parallax } from 'react-parallax';

import ProfileCard from '../components/ProfileCard';
import UpNorthLogo from '../components/UpNorthLogo';
import SEO from '../components/SEO';
import NavBar from '../components/NavBar';

import {
  FacebookIcon,
  LinkedinIcon,
  InstagramIcon,
  LinkIcon,
} from '../components/Icons8Icon';

import messages from '../messages';

import upnorthLogo from '../images/logos/upnorth.svg';

import dyrketLogo from '../images/logos/dyrket_logo.png';
import digilegeLogo from '../images/logos/digilege_logo.png';
import legalaLogo from '../images/logos/legala_logo.png';
import evaksineLogo from '../images/logos/evaksine_logo.png';
import infraspaceLogo from '../images/logos/infraspace_logo.png';
import ptproLogo from '../images/logos/ptpro_logo.png';

import dyrketScreen from '../images/screenshots/dyrket_screen.jpg';
import legalaScreen from '../images/screenshots/legala_screen.jpg';
import evaksineScreen from '../images/screenshots/evaksine_screen.jpg';
import digilegeScreen from '../images/screenshots/digilege_screen.jpg';
import infraspaceScreen from '../images/screenshots/infraspace_screen.jpg';
import ptproScreen from '../images/screenshots/ptpro_screen.jpg';

import jorgenHarsem from '../images/profile/jorgen_harsem.jpg';
import andreasEraker from '../images/profile/andreas_eraker.jpg';
import janetHarsem from '../images/profile/janet_harsem.png';
import andreasHansen from '../images/profile/andreas_hansen.jpg';

import mountainsFar from '../images/graphics/mountainsFar.svg';
import person from '../images/graphics/person.svg';
import mountains from '../images/graphics/mountains.svg';

const useStyles = makeStyles((theme) => ({
  logoWrapper: {},
  logo: {
    height: '30px',
    width: 'auto',
    marginBottom: '2vh',
  },
  screenshotWrapper: {
    marginBottom: theme.spacing(2),
  },
  screenshot: {
    maxWidth: '100%',
    maxHeight: '300px',
    boxShadow: theme.shadows[3],
  },
  footerLogo: {
    height: '30px',
    marginBottom: '5px',
    width: 'auto',
    filter: 'brightness(0.3) invert(1)',
  },
  headline: {
    marginBottom: theme.spacing(2),
  },
  headlineLogo: {
    width: '300px',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: '450px',
      marginBottom: theme.spacing(6),
    },
  },
  section: {
    padding: 24,
    paddingBottom: theme.spacing(5),
  },
  sectionGrey: {
    backgroundColor: theme.palette.grey['300'],
  },
  firstSection: {
    marginTop: 24,
  },
  sectionHeading: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  portfolioWrapper: {
    marginTop: theme.spacing(5),
    // marginBottom: theme.spacing(5),
    width: '100%',
    /* [theme.breakpoints.up('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    }, */
  },
  portfolioLogoWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
    textAlign: 'center',
  },
  portfolioLogo: {
    // filter: 'brightness(0.8) grayscale(1) invert(0)',
    // '&:hover': {
    //  filter: 'brightness(0) grayscale(1) invert(0.8)',
    // },
    height: '30px',
    [theme.breakpoints.up('sm')]: {
      height: '30px',
    },
    [theme.breakpoints.up('md')]: {
      height: '30px',
    },
    transition: [['filter', '300ms']],
    cursor: 'pointer',
  },
  portfolioText: {
    minHeight: '100px',
  },
  whoWeAre: {
    textAlign: 'center',
  },
  whoWeAreParagraph: {
    marginBottom: theme.spacing(2),
    fontSize: '20px',
  },
  footer: {
    backgroundColor: 'black',
    color: 'white',
    padding: 24,
    textAlign: 'center',
  },
  footerText: {
    color: 'white',
    textAlign: 'center',
  },
  topContainer: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
    backgroundColor: theme.palette.grey['100'],
  },
  parallaxLayer: {
    // filter: 'brightness(0.8) grayscale(1) invert(0)',
    // '&:hover': {
    //  filter: 'brightness(0) grayscale(1) invert(0.8)',
    // },
    height: '500px',
    width: '667px',
    [theme.breakpoints.up('sm')]: {
      height: '500px',
    },
    [theme.breakpoints.up('md')]: {
      height: '500px',
      width: '667px',
    },
    transition: [['filter', '300ms']],
    cursor: 'pointer',
  },
  contactLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));

export default function Index() {
  const classes = useStyles();

  return (
    <div>
      <SEO />
      <NavBar />

      <div className={classes.topContainer}>
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              md={6}
              align="left"
              className={classes.logoWrapper}
            >
              <Typography variant="h3" className={classes.headline}>
                {/* <FormattedMessage {...messages.headline} /> */}
                <img
                  src={upnorthLogo}
                  className={classes.headlineLogo}
                  alt="headline-logo"
                />
              </Typography>
              <Typography variant="h5" className={classes.headline}>
                {/*<FormattedMessage {...messages.slogan} />*/}
                We believe in building companies from scratch with a bootstrap
                mentality that makes them profitable early on.
              </Typography>

              <Typography variant="h5" className={classes.headline}>
                Are you an entrepreneur, investor or just curious, then let us
                know at{' '}
                <a
                  href="mailto:hello@upnorth.vc"
                  className={classes.contactLink}
                >
                  hello@upnorth.vc
                </a>
              </Typography>

              <Grid
                className={classes.portfolioWrapper}
                container
                justify="left"
                alignItems="center"
                spacing={4}
              >
                <Grid
                  item
                  xs={6}
                  md={4}
                  className={classes.portfolioLogoWrapper}
                >
                  <ScrollLink to="dyrket" duration={300} offset={-80} smooth>
                    <img
                      alt="dyrket"
                      src={dyrketLogo}
                      className={classes.portfolioLogo}
                    />
                  </ScrollLink>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  className={classes.portfolioLogoWrapper}
                >
                  <ScrollLink to="legala" duration={300} offset={-80} smooth>
                    <img
                      alt="legala"
                      src={legalaLogo}
                      className={classes.portfolioLogo}
                    />
                  </ScrollLink>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  className={classes.portfolioLogoWrapper}
                >
                  <ScrollLink to="evaksine" duration={300} offset={-80} smooth>
                    <img
                      alt="evaksine"
                      src={evaksineLogo}
                      className={classes.portfolioLogo}
                    />
                  </ScrollLink>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  className={classes.portfolioLogoWrapper}
                >
                  <ScrollLink to="digilege" duration={300} offset={-80} smooth>
                    <img
                      alt="digilege"
                      src={digilegeLogo}
                      className={classes.portfolioLogo}
                    />
                  </ScrollLink>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  className={classes.portfolioLogoWrapper}
                >
                  <ScrollLink
                    to="infraspace"
                    duration={300}
                    offset={-80}
                    smooth
                  >
                    <img
                      alt="infraspace"
                      src={infraspaceLogo}
                      className={classes.portfolioLogo}
                    />
                  </ScrollLink>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  className={classes.portfolioLogoWrapper}
                >
                  <ScrollLink to="ptpro" duration={300} offset={-80} smooth>
                    <img
                      alt="ptpro"
                      src={ptproLogo}
                      className={classes.portfolioLogo}
                    />
                  </ScrollLink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <Parallax bgImage={mountainsFar} strength={300}>
                <div className={classes.parallaxLayer}>
                  <Parallax bgImage={mountains} strength={200}>
                    <div className={classes.parallaxLayer}>
                      <Parallax bgImage={person} strength={30}>
                        <div className={classes.parallaxLayer}></div>
                      </Parallax>
                    </div>
                  </Parallax>
                </div>
              </Parallax>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* <Grid
        container
        justify="center"
        alignItems="flex-start"
        className={`${classes.section} 
            ${classes.firstSection}`}
      >
        <Grid
          spacing={3}
          container
          justify="center"
          alignItems="flex-start"
          className={classes.sectionInner}
        >
          <Grid item xs={12} className={classes.sectionHeading}>
            <ScrollElement name="whatWeDo">
              <Typography variant="h3">
                <FormattedMessage {...messages.whatWeDo} />
              </Typography>
            </ScrollElement>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.keyPoint}>
            <KeyPoint
              icon={<SproutIcon />}
              text={<FormattedMessage {...messages.foundAndBuildCompanies} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.keyPoint}>
            <KeyPoint
              icon={<MuscleIcon />}
              text={<FormattedMessage {...messages.sweatEquity} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.keyPoint}>
            <KeyPoint
              icon={<HandshakeIcon />}
              text={<FormattedMessage {...messages.partner} />}
            />
          </Grid>
        </Grid>
      </Grid> */}

      <Grid
        container
        justify="center"
        alignItems="flex-start"
        className={`${classes.section} 
            ${classes.firstSection}`}
      >
        <Container maxWidth="lg">
          <Grid spacing={4} container justify="center" alignItems="flex-start">
            <Grid item xs={12} className={classes.sectionHeading}>
              <ScrollElement name="portfolio">
                <Typography variant="h3">Our focus</Typography>
              </ScrollElement>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h5"
                className={classes.headline}
                align="center"
              >
                We build software companies with a main focus on niche markets
                where competition is limited. We have synergies across all the
                companies in our portfolio of technology and people
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        className={`${classes.section} 
            ${classes.firstSection}`}
      >
        <Container maxWidth="lg">
          <Grid spacing={4} container justify="center" alignItems="flex-start">
            <Grid item xs={12} className={classes.sectionHeading}>
              <ScrollElement name="portfolio">
                <Typography variant="h3">
                  <FormattedMessage {...messages.companies} />
                </Typography>
              </ScrollElement>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              item
              xs={12}
              md={6}
            >
              <Grid
                item
                xs={12}
                align="center"
                className={classes.screenshotWrapper}
              >
                <ScrollElement name="dyrket">
                  <img
                    alt="dyrket-screenshot"
                    src={dyrketScreen}
                    className={classes.screenshot}
                  />
                </ScrollElement>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" align="center" gutterBottom>
                  Dyrket
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center" gutterBottom>
                  Founded: 2017
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom className={classes.portfolioText}>
                  Dyrket.no connects farmers and consumers through our
                  farm-to-table platform. Over 200 local producers are selling
                  to consumers and business through our platform.
                </Typography>
              </Grid>
              <Grid item container xs={12} justify="center">
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://www.dyrket.no"
                    target="_blank"
                  >
                    <LinkIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://www.facebook.com/dyrketno/"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://www.instagram.com/dyrket.no/"
                    target="_blank"
                  >
                    <InstagramIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              item
              xs={12}
              md={6}
            >
              <Grid
                item
                xs={12}
                align="center"
                className={classes.screenshotWrapper}
              >
                <ScrollElement name="digilege">
                  <img
                    alt="digilege-screenshot"
                    src={digilegeScreen}
                    className={classes.screenshot}
                  />
                </ScrollElement>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" align="center" gutterBottom>
                  Digilege
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center" gutterBottom>
                  Founded: 2018
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom className={classes.portfolioText}>
                  Digilege provides easy access to medical services such as
                  tests and home-treatments through its online platform.
                </Typography>
              </Grid>
              <Grid item container xs={12} justify="center">
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://digilege.no"
                    target="_blank"
                  >
                    <LinkIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              item
              xs={12}
              md={6}
            >
              <Grid
                item
                xs={12}
                align="center"
                className={classes.screenshotWrapper}
              >
                <ScrollElement name="evaksine">
                  <img
                    alt="evaksine-screenshot"
                    src={evaksineScreen}
                    className={classes.screenshot}
                  />
                </ScrollElement>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="h4" gutterBottom>
                  eVaksine
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="h6" gutterBottom>
                  Founded: 2018
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom className={classes.portfolioText}>
                  eVaksine provides easy access to vaccines through our
                  web-platform. Order vaccines online, and get the injection at
                  your closest pharmacy.
                </Typography>
              </Grid>
              <Grid item container xs={12} justify="center">
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://evaksine.no"
                    target="_blank"
                  >
                    <LinkIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://www.facebook.com/evaksine/"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>

            {/* <Grid
              container
              spacing={2}
              alignItems="flex-start"
              item
              xs={12}
              md={6}
            >
              <Grid
                item
                xs={12}
                align="center"
                className={classes.screenshotWrapper}
              >
                <ScrollElement name="inrelation">
                  
                  <img
                    alt="inrelation-screenshot"
                    src={inrelationScreen}
                    className={classes.screenshot}
                  />
                </ScrollElement>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography align="center" variant="h4" gutterBottom>
                    Inrelation
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" variant="h6" gutterBottom>
                    Founded: 2018
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom className={classes.portfolioText}>
                    Inrelation connects companies and investors thorugh its
                    cloud based reporting platform
                  </Typography>
                </Grid>
                <Grid item container xs={12} justify="center">
                  <Grid item>
                    <IconButton
                      color="primary"
                      component="a"
                      href="https://www.inrelation.io"
                      target="_blank"
                    >
                      <LinkIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid> */}

            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              item
              xs={12}
              md={6}
            >
              <Grid
                item
                xs={12}
                align="center"
                className={classes.screenshotWrapper}
              >
                <ScrollElement name="legala">
                  <img
                    alt="legala-screenshot"
                    src={legalaScreen}
                    className={classes.screenshot}
                  />
                </ScrollElement>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" align="center" gutterBottom>
                  Legala
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center" gutterBottom>
                  Founded: 2019
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom className={classes.portfolioText}>
                  Legala is a case management system for bankruptcy estates. The
                  system is cloud based, and provides a full set of features for
                  all the tasks required during the case, including collection
                  of public information, generation of reports and documents,
                  and accounting.
                </Typography>
              </Grid>
              <Grid item container xs={12} justify="center">
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://legala.no"
                    target="_blank"
                  >
                    <LinkIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://www.facebook.com/Legala-109833513979932/"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://www.linkedin.com/company/legala/"
                    target="_blank"
                  >
                    <LinkedinIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>

            {/* <Grid
              container
              spacing={2}
              alignItems="flex-start"
              item
              xs={12}
              md={6}
            >
              <Grid
                item
                xs={12}
                align="center"
                className={classes.screenshotWrapper}
              >
                <ScrollElement name="vilbidra">
                  
                  <img
                    alt="vilbidra-screenshot"
                    src={vilBidraScreen}
                    className={classes.screenshot}
                  />
                </ScrollElement>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" align="center" gutterBottom>
                  VilBidra
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center" gutterBottom>
                  Founded: 2020
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom className={classes.portfolioText}>
                  VilBidra is a platform where retirees can exchange services.
                  The platform enables elderly to have the retirement they want
                  and deserve, and also provide useful services to the
                  community.
                </Typography>
              </Grid>
              <Grid item container xs={12} justify="center">
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://vilbidra.no"
                    target="_blank"
                  >
                    <LinkIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://www.facebook.com/VilBidra/"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    component="a"
                    href="https://www.linkedin.com/company/vilbidra/"
                    target="_blank"
                  >
                    <LinkedinIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid> */}

            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              item
              xs={12}
              md={6}
            >
              <Grid
                item
                xs={12}
                align="center"
                className={classes.screenshotWrapper}
              >
                <ScrollElement name="infraspace">
                  <img
                    alt="infraspace-screenshot"
                    src={infraspaceScreen}
                    className={classes.screenshot}
                  />
                </ScrollElement>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography align="center" variant="h4" gutterBottom>
                    Infraspace
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" variant="h6" gutterBottom>
                    Founded: 2021
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom className={classes.portfolioText}>
                    Infraspace develops smart technology fro sustainable
                    infrastructure, and is on a mission to reduce the carbon
                    footprint from infrastructure projects.
                  </Typography>
                </Grid>
                <Grid item container xs={12} justify="center">
                  <Grid item>
                    <IconButton
                      color="primary"
                      component="a"
                      href="https://www.infraspace.tech"
                      target="_blank"
                    >
                      <LinkIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              item
              xs={12}
              md={6}
            >
              <Grid
                item
                xs={12}
                align="center"
                className={classes.screenshotWrapper}
              >
                <ScrollElement name="ptpro">
                  <img
                    alt="infraspace-screenshot"
                    src={ptproScreen}
                    className={classes.screenshot}
                  />
                </ScrollElement>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography align="center" variant="h4" gutterBottom>
                    PTPro
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" variant="h6" gutterBottom>
                    Founded: 2021
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom className={classes.portfolioText}>
                    PTPro develops smart automation and business management software for the
                    health and fitness industry.
                  </Typography>
                </Grid>
                <Grid item container xs={12} justify="center">
                  <Grid item>
                    <IconButton
                      color="primary"
                      component="a"
                      href="#"
                      disabled
                      //target="_blank"
                    >
                      <LinkIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid
        container
        justify="center"
        className={`${classes.section} ${classes.sectionGrey}`}
      >
        <Grid item xs={12} align="center">
          <Container maxWidth="lg">
            <Grid spacing={3} container justify="center">
              <Grid item xs={12} className={classes.sectionHeading}>
                <ScrollElement name="team">
                  <Typography variant="h3">
                    <FormattedMessage {...messages.team} />
                  </Typography>
                </ScrollElement>
              </Grid>
              <Grid item xs={12} sm={9} className={classes.whoWeAre}>
                <Typography className={classes.whoWeAreParagraph}>
                  <FormattedMessage {...messages.formedIn2017} />
                </Typography>
                <Typography className={classes.whoWeAreParagraph}>
                  <FormattedMessage {...messages.weAreGrowing} />
                </Typography>
                <Button
                  href="mailto:jorgen.harsem@upnorth.vc"
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                >
                  <FormattedMessage {...messages.getInTouch} />
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        className={`${classes.section} ${classes.sectionGrey}`}
      >
        <Container maxWidth="lg">
          <Grid spacing={3} container justify="center">
            <Grid item xs={12} sm={4}>
              <ProfileCard name="Jørgen Harsem" imageUrl={jorgenHarsem} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ProfileCard
                name="Andreas Juvkam Eraker"
                imageUrl={andreasEraker}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ProfileCard name="Janet Harsem" imageUrl={janetHarsem} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ProfileCard
                name="Andreas Finn Hansen"
                imageUrl={andreasHansen}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid container justify="center" className={classes.footer}>
        <Grid item xs={12}>
          {/* <UpNorthLogo className={classes.footerLogo} />
          <Typography variant="body1" className={classes.footerText}>
            UpNorth Ventures
          </Typography> */}
          <img
            src={upnorthLogo}
            className={classes.footerLogo}
            alt="footer-logo"
          />
        </Grid>
        <Grid item xs={12}>
          <IconButton
            color="inherit"
            component="a"
            href="https://www.linkedin.com/company/upnorthvc/"
            target="_blank"
            alt="upnorth-linkedin"
          >
            <LinkedinIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}
