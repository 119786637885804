/**
 *
 * ProfileCard
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  avatar: {
    textAlign: 'center',
    width: 150,
    height: 150,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  name: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  subtitle: {
    textAlign: 'center',
  },
}));

function ProfileCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Avatar
        alt={props.name}
        src={props.imageUrl}
        className={classes.avatar}
      />
      <Typography variant="h6" className={classes.name}>
        {props.name}
      </Typography>
      {props.subtitle && (
        <Typography variant="subheading" className={classes.subtitle}>
          {props.subtitle}
        </Typography>
      )}
      {props.description && (
        <Typography variant="body1">{props.description}</Typography>
      )}
    </div>
  );
}

ProfileCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
};

export default ProfileCard;
