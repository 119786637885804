/**
 *
 * UpNorthLogo
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@material-ui/core/SvgIcon';

function UpNorthLogo(props) {
  return (
    <SvgIcon viewBox="0 0 51 17" className={props.className}>
      <path d="m 40.729924,15.027494 10.15,-10.5009999 -1.798,-1.738 -8.413,8.7039999 -11.385,-11.0040004 -5.393,5.5790005 -3.497,-3.438 -6.821,7.0779996 -1.613,-1.5589996 -2.657,-2.569 h -0.001 l -8.953,9.2629999 1.798,1.738 7.216,-7.4650003 7.669,7.4120003 1.738,-1.796 -3.4,-3.287 5.072,-5.2619999 3.494,3.4359996 5.408,-5.5939996 z" />
    </SvgIcon>
  );
}

UpNorthLogo.propTypes = {
  className: PropTypes.string,
};

export default UpNorthLogo;
