/*
 * FrontPage Messages
 *
 * This contains all the text for the FrontPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  headline: {
    id: 'app.containers.FrontPage.headline',
    defaultMessage: 'UpNorth Ventures',
  },
  slogan: {
    id: 'app.containers.FrontPage.slogan',
    defaultMessage:
      'Together with our entrepreneurs, we develop, finance and scale profitable companies',
  },
  team: {
    id: 'app.containers.FrontPage.team',
    defaultMessage: 'Team',
  },
  portfolio: {
    id: 'app.containers.FrontPage.portfolio',
    defaultMessage: 'Portfolio',
  },
  rapidGrowth: {
    id: 'app.containers.FrontPage.rapidGrowth',
    defaultMessage: 'Focus on companies with rapid growth potential',
  },
  inOslo: {
    id: 'app.containers.FrontPage.inOslo',
    defaultMessage: 'Located in Oslo, Norway',
  },
  earlyStage: {
    id: 'app.containers.FrontPage.earlyStage',
    defaultMessage: 'Early stage investor',
  },
  forHire: {
    id: 'app.containers.FrontPage.forHire',
    defaultMessage: 'CTO for hire',
  },
  formedIn2017: {
    id: 'app.containers.FrontPage.formedIn2017',
    defaultMessage:
      'UpNorth Ventures was formed in 2017 by a team of experienced entrepreneurs/executives with broad business, financial, tech and entrepreneurial backgrounds. The team is supported by a network of tech resources and other.',
  },
  weAreGrowing: {
    id: 'app.containers.FrontPage.weAreGrowing',
    defaultMessage:
      'We are growing, and currently looking for new partners and employees.',
  },
  getInTouch: {
    id: 'app.containers.FrontPage.getInTouch',
    defaultMessage: 'Get in touch',
  },
  whoWeAre: {
    id: 'app.containers.FrontPage.whoWeAre',
    defaultMessage: 'Who we are',
  },
  whatWeDo: {
    id: 'app.containers.FrontPage.whatWeDo',
    defaultMessage: 'What we do',
  },
  close: {
    id: 'app.containers.FrontPage.close',
    defaultMessage: 'Close',
  },
  foundAndBuildCompanies: {
    id: 'app.containers.FrontPage.foundAndBuildCompanies',
    defaultMessage: 'Found and build companies',
  },
  sweatEquity: {
    id: 'app.containers.FrontPage.sweatEquity',
    defaultMessage: 'Invest in companies through sweat equity',
  },
  partner: {
    id: 'app.containers.FrontPage.partner',
    defaultMessage: 'Act as a technology-, admin- or commercial partner',
  },
  companies: {
    id: 'app.containers.FrontPage.companies',
    defaultMessage: 'Companies',
  },
});
